var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c("c-tab", {
          attrs: {
            dense: true,
            tabItems: _vm.tabItems,
            height: _vm.tabHeight,
            inlineLabel: true,
          },
          on: { tabClick: _vm.tabClick },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: {
                      count: _vm.count,
                      popupParam: _vm.popupParam,
                      equipInfo: _vm.equipInfo,
                      attachInfo: _vm.attachInfo,
                    },
                    on: {
                      "update:popupParam": function ($event) {
                        _vm.popupParam = $event
                      },
                      "update:popup-param": function ($event) {
                        _vm.popupParam = $event
                      },
                      "update:equipInfo": function ($event) {
                        _vm.equipInfo = $event
                      },
                      "update:equip-info": function ($event) {
                        _vm.equipInfo = $event
                      },
                      "update:attachInfo": function ($event) {
                        _vm.attachInfo = $event
                      },
                      "update:attach-info": function ($event) {
                        _vm.attachInfo = $event
                      },
                      closePopup: _vm.closePopup,
                      saveCallback: _vm.saveCallback,
                      changeStatus: _vm.changeStatus,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }